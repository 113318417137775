























































































































































import Vue from 'vue'

import {
  BOverlay,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
  BButton,
  BvEvent,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import { tinyOptions } from '@core/mixins/tinyOptions'
import vSelect from 'vue-select'
import Editor from '@tinymce/tinymce-vue'
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE'
import Ripple from 'vue-ripple-directive'
import AvailablePlaceholder from '@/views/components/available-placeholder/AvailablePlaceholder.vue'
import { appTuitionCardTemplate } from '@/store/modules/app-tuition-card-templates/app-tuition-card-template-item'
import SaveTuitionCardTemplateModal
  from '@/views/admin/tuition-card-templates/tuition-card-template-item/save-tuition-card-template-modal/SaveTuitionCardTemplateModal.vue'

export default Vue.extend({
  name: 'TuitionCardTemplateItem',
  components: {
    vSelect,
    SaveTuitionCardTemplateModal,
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BCard,
    BForm,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    Editor,

    AvailablePlaceholder,
  },
  directives: {
    Ripple,
  },
  mixins: [tinyOptions],
  data() {
    return {
      required,
    }
  },
  computed: {
    isLoading() {
      return appTuitionCardTemplate.getters.getIsLoading
    },
    tuitionCardTemplate() {
      return appTuitionCardTemplate.getters.getTuitionCardTemplate
    },
    tuitionCardTemplateId() {
      return appTuitionCardTemplate.getters.getTuitionCardTemplateId
    },
    isButtonDisabled() {
      return appTuitionCardTemplate.getters.getIsButtonDisabled
    },
    lastCursorPosition() {
      return appTuitionCardTemplate.getters.getLastCursorPosition
    },
    tuitionCardPlaceholders() {
      return appTuitionCardTemplate.getters.getTuitionCardPlaceholders
    },
    selectedTCPlaceholder: {
      get() {
        return appTuitionCardTemplate.getters.getSelectedTCPlaceholder
      },
      set(val: string) {
        appTuitionCardTemplate.mutations.SET_SELECTED_TC_PLACEHOLDER(val)
      },
    },
  },
  watch: {
    selectedTCPlaceholder(val) {
      if (val) {
        this.insertValueToEditor(val)
        this.selectedTCPlaceholder = null
      }
    },
  },
  async created() {
    appTuitionCardTemplate.mutations.SET_TUITION_CARD_TEMPLATE_ID(this.$router.currentRoute.params.id)
    await this.setTuitionCardTemplate()
    await this.fetchTuitionCardPlaceholders()
  },
  beforeDestroy() {
    appTuitionCardTemplate.mutations.INIT_STATE()
  },
  methods: {
    async setTuitionCardTemplate() {
      if (this.tuitionCardTemplateId) {
        await appTuitionCardTemplate.actions.fetchTuitionCardTemplate(this.tuitionCardTemplateId)
      } else {
        appTuitionCardTemplate.mutations.INIT_TUITION_CARD_TEMPLATE()
      }
    },
    async saveChanges() {
      return new Promise((resolve, reject) => {
        (this.$refs.tuitionCardTemplateRules as InstanceType<typeof ValidationObserver>).validate().then(success => {
          if (success) {
            if (this.tuitionCardTemplateId) {
              this.updateTemplate()
            } else {
              this.createTemplate()
            }
          } else {
            reject()
          }
        })
      })
    },
    async updateTemplate() {
      await appTuitionCardTemplate.actions.updateTuitionCardTemplate()
      // this.$router.push({ name: 'admin-tuition-card-templates' })
    },
    async createTemplate() {
      await appTuitionCardTemplate.actions.createTuitionCardTemplate()
      // this.$router.push({ name: 'admin-tuition-card-templates' })
    },
    setCursorPosition(event: BvEvent) {
      appTuitionCardTemplate.mutations.SET_LAST_CURSOR_POSITION(event.target.selectionEnd)
    },
    insertValueToEditor(val: string) {
      getTinymce().activeEditor.execCommand('mceInsertContent', false, val)
    },
    openSaveAsModal() {
      return new Promise((resolve, reject) => {
        (this.$refs.tuitionCardTemplateRules as InstanceType<typeof ValidationObserver>).validate().then(success => {
          if (success) {
            this.$bvModal.show('save-tuition-card-template-modal')
          } else {
            reject()
          }
        })
      })
    },
    fetchTuitionCardPlaceholders() {
      appTuitionCardTemplate.actions.fetchTuitionCardPlaceholders()
    },
  },
})
