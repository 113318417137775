import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import store from '@/store'
import { ITuitionCardTemplate, TuitionCardTemplateItemState, ITuitionCardPlaceholder } from './types'
import {
  createTuitionCardTemplate,
  fetchTuitionCardPlaceholders,
  fetchTuitionCardTemplate,
  updateTuitionCardTemplate,
} from '@/api/tuitionCardTemplates'

// eslint-disable-next-line import/prefer-default-export
export const appTuitionCardTemplate = createModule(
  'appTuitionCardTemplate',
  {
    namespaced: true,
    state: {
      isLoading: false,
      tuitionCardTemplateId: null,
      tuitionCardTemplate: {
        name: '',
        body: '',
      },
      isButtonDisabled: false,
      lastCursorPosition: 0,
      new_name: '',
      tuitionCardPlaceholders: [],
      selectedTCPlaceholder: null,
    } as TuitionCardTemplateItemState,
    getters: {
      getIsLoading: state => state.isLoading,
      getTuitionCardTemplateId: state => state.tuitionCardTemplateId,
      getTuitionCardTemplate: state => state.tuitionCardTemplate,
      getIsButtonDisabled: state => state.isButtonDisabled,
      getLastCursorPosition: state => state.lastCursorPosition,
      getTuitionCardPlaceholders: state => state.tuitionCardPlaceholders,
      getSelectedTCPlaceholder: state => state.selectedTCPlaceholder,
    },
    mutations: {
      SET_TUITION_CARD_TEMPLATE_ID(state, val: any) {
        state.tuitionCardTemplateId = val
      },
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_TUITION_CARD_TEMPLATE(state, val: ITuitionCardTemplate) {
        state.tuitionCardTemplate = val
      },
      SET_IS_BUTTON_DISABLED(state, val: boolean) {
        state.isButtonDisabled = val
      },
      SET_LAST_CURSOR_POSITION(state, val: number) {
        state.lastCursorPosition = val
      },
      INIT_TUITION_CARD_TEMPLATE(state) {
        state.tuitionCardTemplate = {
          name: '',
          body: '',
          program_id: store.getters['verticalMenu/getDefaultProgram'],
        }
      },
      INIT_STATE(state) {
        state.isLoading = false
        state.tuitionCardTemplate = {
          name: '',
          body: '',
          program_id: store.getters['verticalMenu/getDefaultProgram'],
        }
        state.isButtonDisabled = false
        state.lastCursorPosition = 0
      },
      SET_NEW_NAME(state, val: string) {
        state.new_name = val
      },
      SET_TUITION_CARD_PLACEHOLDERS(state, val: ITuitionCardPlaceholder[]) {
        state.tuitionCardPlaceholders = val
      },
      SET_SELECTED_TC_PLACEHOLDER(state, val: string | null) {
        state.selectedTCPlaceholder = val
      },
    },
    actions: {
      async saveAsTuitionCardTemplate({ state }) {
        try {
          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(true)

          const queryParams = {
            id: state.tuitionCardTemplate.id,
            name: state.new_name,
            body: state.tuitionCardTemplate.body,
            program_id: state.tuitionCardTemplate.program_id,
          }

          const response = await createTuitionCardTemplate(queryParams)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'New Tuition Card template was successfully created',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
          appTuitionCardTemplate.mutations.SET_NEW_NAME('')
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Tuition Card template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        } finally {
          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async createTuitionCardTemplate({ state }) {
        try {
          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(true)
          const response = await createTuitionCardTemplate(state.tuitionCardTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'New Tuition Card template was successfully created',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Tuition Card template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        } finally {
          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async fetchTuitionCardTemplate(ctx, id) {
        try {
          appTuitionCardTemplate.mutations.SET_IS_LOADING(true)
          const response = await fetchTuitionCardTemplate(id)
          appTuitionCardTemplate.mutations.SET_TUITION_CARD_TEMPLATE(response.data.data)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Tuition Card template item',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appTuitionCardTemplate.mutations.SET_IS_LOADING(false)
        }
      },
      async updateTuitionCardTemplate({ state }) {
        try {
          const updatedTuitionCardTemplate = {
            id: state.tuitionCardTemplate.id,
            name: state.tuitionCardTemplate.name,
            body: state.tuitionCardTemplate.body,
            program_id: state.tuitionCardTemplate.program_id,
          }

          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(true)

          await updateTuitionCardTemplate(updatedTuitionCardTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tuition Card Template was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating Tuition Card Template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appTuitionCardTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async fetchTuitionCardPlaceholders({ state }) {
        const response = await fetchTuitionCardPlaceholders()
        const tcPlaceholders = response.data.data.map((item: { value: any; text: any }) => ({
          value: `{{tc:${item.value}}}`,
          label: item.text,
        }))
        appTuitionCardTemplate.mutations.SET_TUITION_CARD_PLACEHOLDERS(tcPlaceholders)
      },
    },
  },
)

appTuitionCardTemplate.register(store)
